<template>
  <div>
    <div class="card card-custom gutter-b example example-compact">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Ilova {{ $t('ACTION.DETAILS') }}</h3>
        </div>
      </div>
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-body">
          <div v-if="isLoading" class="text-center mt-5">
            <b-spinner
              style="width: 3rem; height: 3rem"
              label="Large Spinner"
            ></b-spinner>
          </div>
          <div>
            <div v-if="!isLoading" class="zebra__wrapper">
              <table class="zebra">
                <thead>
                  <tr>
                    <th class="table__cell" rowspan="2">Manzil</th>
                    <th
                      class="table__cell"
                      colspan="3"
                      v-for="truck in gettruck"
                      :key="truck.id"
                    >
                      {{
                        truckModel(truck, selectedRow).trucktype_description
                          .brand.name
                      }}
                      {{
                        truckModel(truck, selectedRow).trucktype_description
                          .model
                      }}
                      <div>
                        (yuk ogirligi:
                        {{
                          truckModel(truck, selectedRow).trucktype_description
                            .capacity_kg
                        }}
                        kg <br />
                        yuk sig'imi:
                        {{
                          truckModel(truck, selectedRow).trucktype_description
                            .capacity_m3
                        }}
                        m3)
                        <span
                          class="
                            label label-lg label-light-warning label-inline
                          "
                          v-if="truckModel(truck, selectedRow).is_ref"
                        >
                          Ref
                        </span>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th
                      class="table__cell"
                      v-for="i in gettruck.length * 3"
                      :key="i"
                    >
                      <strong> {{ i | headTable }} </strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="marshrut in selectedRow.marshrut_description"
                    :key="marshrut.id"
                  >
                    <td
                      class="table__cell"
                      style="
                        border-left: 1px solid #000;
                        position: sticky;
                        left: 0;
                      "
                    >
                      <dir style="display: flex">
                        <span
                          style="display: flex"
                          v-for="(item, index) in marshrut.cities"
                          :key="item.id"
                        >
                          {{ item.city_name.name }}
                          <span v-if="index !== marshrut.cities.length - 1">
                            <span>—</span>
                          </span>
                        </span>
                      </dir>
                    </td>
                    <td
                      class="table__cell"
                      v-for="(truck, index) in gettruck.length * 3"
                      :key="index"
                    >
                      {{ index | truckPrice(marshrut.prices, gettruck) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      list: {},
      loading: true
    }
  },
  beforeCreate() {
    this.$store.dispatch('getStandartContAppen', this.$route.params.id)
  },
  filters: {
    formatDate: function (val) {
      if (val !== null) {
        return val.split('-').reverse().join('-')
      }
    },
    headTable(index) {
      // console.log('1231')
      if (index % 3 == 1) {
        return 'Summa'
      } else if (index % 3 == 2) {
        return 'Nds'
      } else if (index % 3 == 0) {
        return 'Summa(nds)'
      } else return ''
    },
    truckPrice(index, list, truckType) {
      var i = Math.floor(index / 3)
      let data = ''
      data = list.find(
        (x) =>
          x.truck_type == truckType[i].truck_type &&
          x.is_ref == truckType[i].is_ref
      )
      if (data) {
        // console.log(i, index, data)
        if (index % 3 == 0) {
          data = (data.price && data.price.toLocaleString('zh-ZH')) || 0
        }
        if (index % 3 == 1) {
          data = (data.nds_amout && data.nds_amout.toLocaleString('zh-ZH')) || 0
        }
        if (index % 3 == 2) {
          data =
            (data.price_with_nds &&
              data.price_with_nds.toLocaleString('zh-ZH')) ||
            0
        } else return data
      } else {
        return data
      }
      return data
    }
  },
  methods: {
    truckModel(set, trucks) {
      let data
      if (Boolean(trucks) && Boolean(trucks.marshrut_description))
        trucks.marshrut_description.forEach((truck) => {
          // console.log('truck', truck, set)
          data = truck.prices.find(
            (x) => x.truck_type == set.truck_type && x.is_ref == set.is_ref
          )
        })
      return data
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    gettruck() {
      let data = []
      let myList = []
      if (Boolean(this.selectedRow) && this.selectedRow.marshrut_description) {
        myList = new Set()
        data = this.selectedRow.marshrut_description
        // data.forEach(element => {
        data[data.length - 1].prices.forEach((price) => {
          const data = {
            truck_type: price.truck_type,
            is_ref: price.is_ref
          }
          myList.add(data)
        })
        myList = Array.from(myList)
        // })
      }
      return myList
    },
    selectedRow() {
      let data = this.$store.state.requests.standartContAppen
      return data
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Ilova' + this.$t('ACTION.DETAILS') }
    ])

    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.v-application.v-application--is-ltr.theme--light
  ::v-deep
  .menuable__content__active {
  height: 160px !important;
}
</style>
<style scoped>
.zebra thead tr:first-child th:first-child {
  border-left: 1px solid #000;
  border-bottom: 1px solid #333;
}
.zebra thead tr:first-child th {
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: #fff;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
}
.zebra thead tr:first-child th:first-child {
  position: sticky;
  left: 0;
  z-index: 10;
  background-color: #fff;
}
.zebra thead tr:nth-child(2) th {
  position: sticky;
  top: 69px;
  z-index: 9;
  background-color: #fff;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.zebra tbody tr:first-child td:first-child {
  position: sticky;
  left: 0;
  z-index: 9;
}

.zebra tbody tr td {
  background-color: rgb(255, 255, 255);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.zebra thead {
  z-index: -9;
}
.zebra__wrapper {
  /* height: 450px; */
  overflow-x: scroll;
}
.removeCell {
  cursor: pointer;
  transition: all 0.2s;
}
/* th {
  padding: .rem !important;
} */
.removeCell:hover {
  background-color: rgb(250, 228, 228);
}
.card-wrapper {
  padding-top: 40px;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.card-wrapper__item {
  flex: 0 0 auto !important;
  margin-right: 10px;
}
.zebra__wrapper {
  overflow-x: scroll;
  white-space: nowrap;
  margin: 1rem 0;
}
/* .td__cell {
  padding: 1.25rem;
} */
.table__cell {
  text-align: center;
  font-weight: 400;
  padding: 0.5rem;
}
.td_cell_brand {
  padding: 1.25rem 2rem;
  padding-left: 1.25rem;
}
#chipcustomization {
  width: 100%;
}
#chipcustomization .chipcontent {
  margin: 0 auto;
  width: 100%;
}
.multiselect-chip-custom .e-multi-select-wrapper .e-chips {
  opacity: 0.9;
}

.multiselect-chip-custom .e-multi-select-wrapper .e-chips:hover {
  opacity: 1;
}
.px-50 {
  padding: 0px 50px;
}
.multiselect-chip-custom
  .e-multi-select-wrapper
  .e-chips
  .e-chips-close.e-icon::before,
.multiselect-chip-custom .e-multi-select-wrapper .e-chips .e-chipcontent,
.multiselect-chip-custom .e-multi-select-wrapper .e-chips .e-chipcontent:hover {
  color: #ffffff;
}
.e-multi-select-wrapper .e-chips {
  background-color: #ff843d;
}
.e-multi-select-wrapper .e-chips:hover {
  background-color: #f5762d;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}
.td__details th {
  width: 1%;
}
th,
td {
  padding: 0.25rem;
  text-align: left;
  /* border: 1px solid #000; */
}
tbody tr:nth-child(odd) {
  background: #eee;
}
tbody tr td input {
  text-align: center;
}
.f-right {
  float: right;
}
.btncard {
  display: inline-block;
  color: #464e5f;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.42rem;
  -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    -webkit-box-shadow 0.3s ease-in-out;
}
.btncard.btn-primarycard {
  background-color: #c0dfff;
  border-color: #c0dfff;
}
.btncard.btn-primarycard:hover {
  background-color: #a2d1ff !important;
}
.list-group-item {
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}
.list-group-item:hover {
  transition: all 0.3s;
  background-color: rgb(242, 242, 242) !important;
}
</style>
